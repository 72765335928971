<op-metadata [componentType]="componentProfile.componentType" [componentProfile]="componentProfile"></op-metadata>

<div class="row p-3">
  <div class="col-sm-12">
    <op-favorites [componentType]="componentProfile.componentType"
                  [hasBlockings]="componentProfile.Blocked"
                  [parentComponentId]="componentProfile.parentId"
    ></op-favorites>
  </div>
</div>

<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-actions id="actions"
                [engine]="componentProfile.engine"
                [selfLink]="absolutUrl + '#actions'"
                [componentId]="componentProfile.Id"
                contentContainerId="actions">
    </op-actions>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-logistics id="logistics" [selfLink]="absolutUrl + '#logistics'" [componentId]="componentProfile.Id" contentContainerId="logistics"></op-logistics>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<div class="row p-3">
  <div class="col-sm-12">
    <op-tests id="tests" [selfLink]="absolutUrl + '#tests'" [componentProfile]="componentProfile" contentContainerId="tests"></op-tests>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-component-approval-v2 id="approval" [selfLink]="absolutUrl + '#approval'"
                              [componentId]="componentProfile.Id"
                              contentContainerId="approval"></op-component-approval-v2>
  </div>
</div>

<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-manufacturing-process id="process" [selfLink]="absolutUrl + '#process'"
                              [componentId]="componentProfile.Id"
                              contentContainerId="process">
    </op-manufacturing-process>
  </div>
</div>

<ng-container *ngIf="componentProfile.componentType === ComponentType.ENGINE">
<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-offsets id="offSets"
                [selfLink]="absolutUrl + '#offSets'"
                [componentId]="componentProfile.Id"
                [componentName]="componentProfile.Identifier"
                contentContainerId="offSets">
    </op-offsets>
  </div>
</div>
</ng-container>

<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-measurements id="measurements" [selfLink]="absolutUrl + '#measurements'"
                              [componentId]="componentProfile.Id"
                              contentContainerId="measurements">
    </op-measurements>
  </div>
</div>

<div class="row p-3">
  <div class="col" *ngIf="componentProfile.Id">
    <op-file-explorer id="attachments"
                      [componentId]="componentProfile.Id"
                      [componentType]="componentProfile.componentType?.toLowerCase()"
                      [componentName]="componentProfile.Identifier"
                      [componentCostCenter]="componentProfile.CostCenterWithLineIndex"
                      [selfLink]="absolutUrl + '#attachments'"
                      contentContainerId="attachments">
    </op-file-explorer>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-blockings id="blockings"
                [componentId]="componentProfile.Id"
                [componentName]="componentProfile.Identifier"
                [componentType]="componentProfile.componentType"
                [selfLink]="absolutUrl + '#blockings'"
                contentContainerId="blockings">
    </op-blockings>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<div class="row p-3">
  <div class="col-sm-12">
    <op-reworks id="reworks"
                         [version2]="true"
                         [componentProfile]="componentProfile"
                         [selfLink]="absolutUrl + '#reworks'"
                         contentContainerId="reworks">
    </op-reworks>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-assembly-details id="assembly"
                         [componentId]="componentProfile.Id"
                         [selfLink]="absolutUrl + '#assembly'"
                         [parentComponentId]="componentProfile.parentId"
                         contentContainerId="assembly">
    </op-assembly-details>
  </div>
</div>

<div class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-events id="events" [componentId]="componentProfile.Id"
               [selfLink]="absolutUrl + '#events'"
               contentContainerId="events"></op-events>
  </div>
</div>

<div class="row p-3">
  <div class="col-sm-12">
    <op-telegram-list id="telegrams" [componentName]="componentProfile.Identifier"
               [selfLink]="absolutUrl + '#telegrams'"
                      contentContainerId="telegrams"></op-telegram-list>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<ng-container *ngIf="componentProfile.componentType === ComponentType.ENGINE">
  <div class="row p-3">
    <div class="col-sm-12">
      <op-keycode-history id="keycodeHistory" [componentProfile]="componentProfile"
                          [selfLink]="absolutUrl + '#keycodeHistory'"
                          contentContainerId="keycodeHistory">
      </op-keycode-history>
    </div>
  </div>
</ng-container>

<div *opRequiredRole="'role_admin'" class="row p-3">
  <div class="col-sm-12" *ngIf="componentProfile.Id">
    <op-dlq-messages id="dlq"
                     [componentId]="componentProfile.Id"
                     [selfLink]="absolutUrl + '#events'"
                     contentContainerId="dlq">
    </op-dlq-messages>
  </div>
</div>

<ng-container [ngTemplateOutlet]="pageHeader"></ng-container>
<div class="row p-3">
  <div class="col-sm-12">
    <op-audit-v2
      id="audit"
      [componentElement]="componentProfile"
      [selfLink]="absolutUrl + '#audit'"
      ksu="KSU 7.2 - 15 Jahre"
      contentContainerId="audit">
    </op-audit-v2>
  </div>
</div>

<ng-template #pageHeader>
  <div class="container-fluid print-only">
    <div class="row pt-3">
      <div class="col">
        <div class="d-flex">
          <div class="col pl-0" style="vertical-align: middle;display: inherit;">
            <i class="fas fa-fw cpp-icon-size-sidebar" style="padding-top: 5px"
               [class.fa-cog]="componentProfile.componentType === ComponentType.PART"
               [class.fa-cogs]="componentProfile.componentType === ComponentType.ENGINE">
            </i>
            <span class="text-overflow-ellipses pl-3 pre-white" style="font-size: 1.5em; font-weight: 500">{{ componentProfile.Identifier }}</span>
            <span class="text-right internal-decorator"><i class="fas fa-lock cpp-icon-size" [class.fa-lock]="componentProfile.Blocked"
                                                      [class.fa-lock-open]="!componentProfile.Blocked"
                                                      [style.color]="componentProfile.Blocked ? 'darkred' : 'darkgreen'" style="padding-top: 8px; padding-left: 8px"></i></span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span class="small"><op-component-state-badge [name]="componentProfile.Status"></op-component-state-badge></span>
            <span class="small pl-1"><op-location-info *ngIf="componentProfile.Status === 'IN_PRODUCTION' || componentProfile.Status === 'INCOMPLETE'" [componentName]="componentProfile.Identifier"></op-location-info></span>
          </div>
        </div>
      </div>
      <div class="col-4 pl-0 text-right d-inline">
      </div>
    </div>
  </div>
</ng-template>
