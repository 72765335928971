<op-detail-section-wrapper
  [title]="'component-profile.reworks.title' | translate"
  [description]="'component-profile.reworks.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="items.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <ng-container *ngIf="items.length >= 1 && !version2">
    <ngx-treeview class="cpp-treeview-h-100"
                  [itemTemplate]="treeViewItemTemplate"
                  [config]="treeViewConfig"
                  [items]="treeViewItems">
    </ngx-treeview>
  </ng-container>

  <table class="table table-hover w-75" *ngIf="version2">
    <tbody>
    <tr *ngIf="reworks.length === 0 && !loadingReworks" class="row pl-3 pt-3 pb-3 pr-3">
      <div class="col pl-0 text-center">
        <small> {{ 'global.nothing-to-show' | translate }}</small>
      </div>
    </tr>
    <tr class="row pl-3 pr-3" *ngFor="let rework of reworks; let i = index">
      <td class="cpp-column-fixed-width-120 small text-monospace">
        <op-component-state-badge [useIconsAndText]="true" [prependIcon]="true" [name]="'rework_' + rework.reworkType"></op-component-state-badge>
      </td>
      <td class="cpp-column-fixed-width-40 small text-right">
        <a [href]="'actions/reworks/' + rework.id" [target]="'_blank'" >
          <i class="fas fa-link"></i>
        </a>
      </td>
      <td class="cpp-column-fixed-width-75 small text-monospace">
        {{ rework.humanReadableId }}
      </td>
      <td class="col small text-overflow-ellipses pre-white">
        {{ rework.complaint }}
      </td>
      <td class="cpp-column-fixed-width-75 small">
        <button class="btn btn-sm btn-primary pt-0 pb-0 pointer" [tooltip]="analysesTooltipTemplate">
          <i class="fas fa-info fa-fw"></i>
        </button>

        <ng-template #analysesTooltipTemplate>
          {{ rework.analysis ? rework.analysis : 'Analyse fehlt.' }} <br><br>
          {{ rework.rework ? rework.rework : 'Nacharbeit noch nicht dokumentiert.' }}
        </ng-template>
      </td>

      <td class="cpp-column-fixed-width-175 small text-left d-xl-block d-none">
        <op-component-state-badge [name]="'rework_' + rework.reworkState"></op-component-state-badge>
      </td>
    </tr>
    <tr *ngIf="loadingReworks" class="row">
      <td class="col-12 text-center">
        <div class="spinner-border spinner-border-sm"></div>
      </td>
    </tr>
    </tbody>
  </table>

</ng-template>

<ng-template #treeViewItemTemplate let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">
  <div class="p-0 pt-1 m-0" style="display: flex; line-height: 19px">
    <i *ngIf="treeViewItem.value.isItemModeType === true"
       (click)="onCollapseExpand()"
       aria-hidden="true"
       class="pr-1"
       [ngSwitch]="treeViewItem.collapsed">

      <i *ngSwitchCase="false" class="fas fa-fw fa-chevron-down small" pr-1></i>
      <i *ngSwitchCase="true" class="fas fa-fw fa-chevron-right small pr-1"></i>
    </i>

    <div *ngIf="treeViewItem.value.isItemModeType === true" class="small text-center p-0" style="width: 25px; text-align: center !important;">
      <i [class.cpp-component-profile-reworks-state-open]="treeViewItem.value.reworkState === 'OPEN'"
         [class.cpp-component-profile-reworks-state-unknown]="treeViewItem.value.reworkState === 'UNKNOWN'"
         [class.cpp-component-profile-reworks-state-closed]="treeViewItem.value.reworkState === 'CLOSED'"
         [class.cpp-component-profile-reworks-state-approved]="treeViewItem.value.reworkState === 'APPROVED'"

         class="fa-fw"
         aria-hidden="true"></i>
    </div>

    <label *ngIf="treeViewItem.value.isItemModeType === true; else details" class="form-check-label text-overflow-ellipses text-monospace small">
      <op-datetime-show [dateTime]="treeViewItem.text"></op-datetime-show>
    </label>
    <ng-template #details>
      <div class="form-check-label pb-1" *ngIf="treeViewItem.value">
        <div class="col small text-overflow-ellipses"
             [class.d-inline-block]="!viewSizeXS" style="min-width: 100px; max-width: 200px; font-weight: 500">{{ treeViewItem.text }}:</div>

        <div class="col small" [class.cpp-component-profile-reworks-viewSizeXS]="viewSizeXS"  [class.d-inline-block]="!viewSizeXS" style="min-width: 100px;">

          <ng-container *ngIf="!dateTimeFields.includes(treeViewItem.text); else dateTimeDetail">
            <ng-container *ngIf=" treeViewItem.text === ('component-profile.reworks.rework' | translate); else showValueOnlyTemplate">

            </ng-container>
            <ng-template #showValueOnlyTemplate>
              {{ treeViewItem.value }}
            </ng-template>
          </ng-container>

          <ng-template #dateTimeDetail>
            <op-datetime-show [dateTime]="treeViewItem.value"></op-datetime-show>
          </ng-template>

        </div>
      </div>
    </ng-template>
    <span *ngIf="treeViewItem.value.isItemModeType === true" class="small pl-3 d-none d-sm-inline-block">{{ treeViewItem.value.analysis }}</span>

    <span *ngIf="treeViewItem.value.isItemModeType === true" class="small pl-3 d-none d-sm-inline-block"><op-component-state-badge [prependIcon]="true" [useIconsAndText]="true" [name]="treeViewItem.value.reworkType + '_REWORK'"></op-component-state-badge></span>
    <span *ngIf="treeViewItem.value.isItemModeType === true" class="small pl-3 d-inline-block d-sm-none"><op-component-state-badge [useIcons]="true" [name]="treeViewItem.value.reworkType + '_REWORK'"></op-component-state-badge></span>

  </div>
</ng-template>








