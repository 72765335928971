import {Component, OnInit} from '@angular/core';
import {EngineService} from '../../../shared/services/backend/engine.service';
import {EngineFilter} from './enginefilter';
import {ReplaySubject, Subject} from 'rxjs';
import {FilterBase} from '../../../shared/models/filterBase';
import {RefreshConfig} from '../../../shared/components/refresh/models/refresh-config';
import {LoggingService} from '../../../core/logging/logging.service';
import {
  EngineResponse,
  EngineState,
  QuickTimeSelector
} from '@cstx/volkswagen-mqs-engine-service-client';
import {TranslateService} from '@ngx-translate/core';
import {
  AttributeEntry,
  FilterValue,
  InputType
} from '../../../shared/components/guided-filter-input/guided-filter-input.component';
import {CostCenterService} from '../../metadata/services/cost-center.service';
import {CostCenterFilter} from '../../metadata/models/costCenterFilter';
import {ErrorHandler} from '../../../shared/services/error-handler/error-handler';
import {
  EnterpriseRoleProviderService
} from '../../../core/services/enterprise-role-provider.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {TrackingService} from '../../../shared/services/backend/tracking.service';
import {Engine, LogisticalQuickInfo} from '../../../shared/services/backend/models/engine';

// TODO: Help for Search
// TODO: Actions menu
// TODO: Error message or disable export on to big count
// TODO: Include retrieving available search attributes like filters edit dialog
// TODO: before finish, check inner TODOs
// TODO: before finsih, check smaller views again

@Component({
  selector: 'op-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  constructor(private engineService: EngineService,
              private translate: TranslateService,
              private costCenterService: CostCenterService,
              private trackingService: TrackingService) { }
  triggerFilterBasedReport = new Subject<FilterBase>();
  countUpdating: boolean;
  count = 0;
  listUpdating: boolean;
  list = new Array<Engine>();
  listFilter = new EngineFilter();
  listSort: string[]

  activeTab = 'all';

  filterValues: Array<FilterValue> = new Array<FilterValue>();

  refreshComponentConfig = new RefreshConfig(true);
  refreshComponentAutoReloadEnabled = new Subject<boolean>();
  refreshComponentAnimationEnabled = new Subject<boolean>();
  refreshComponentAutoReloadInterval = 10000;

  /**
   * TODO: Like many other things, this should be part of search generalization
   * @param searching Is used to disable the input and visualize that we are searching
   * @param searchInvalid Triggers the invalid class on the input search field
   * @param searchString Holds the string we use for searching
   * @param searchBuildDateRange Holds the production date range we use for searching
   * @param searchAliasMappings Alias table for mapping human readable attributes to object attributes
   */
  public clearFilterSub: Subject<void> = new Subject<void>();
  searching: boolean;

  public searchOptionSource: ReplaySubject<Array<AttributeEntry>> = new ReplaySubject<Array<AttributeEntry>>();
  private searchAliasMappings: Array<AttributeEntry> = [
    {key: 'page.engines.table.column.engineCode', value: 'engineCode', inputType: InputType.String},
    {key: 'page.engines.table.column.engineCodeList', value: 'engineCode', inputType: InputType.List},
    {key: 'page.engines.table.column.engineNumber', value: 'engineNumber', inputType: InputType.String},
    {key: 'page.engines.table.column.engineNumberList', value: 'engineNumber', inputType: InputType.List},
    {key: 'page.engines.table.column.buildDate', value: 'buildDate', inputType: InputType.DateRange},
    {key: 'page.engines.table.column.keyCode', value: 'keyCode', inputType: InputType.String},
    {key: 'page.engines.table.column.keyCodeList', value: 'keyCode', inputType: InputType.List},
    {key: 'page.engines.table.column.orderNumber', value: 'orderNumber', inputType: InputType.String},
    {key: 'page.engines.table.column.orderNumberList', value: 'orderNumber', inputType: InputType.List},
    {key: 'page.engines.table.column.engineState', value: 'engineState', inputType: InputType.Selection,
      selection: [
        {key: 'engine.state.IN_PRODUCTION', value: 'IN_PRODUCTION',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorInProduction']},
        {key: 'engine.state.READY_TO_SHIP', value: 'READY_TO_SHIP',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorReadyToShip']},
        {key: 'engine.state.FORCED_READY_TO_SHIP', value: 'FORCED_READY_TO_SHIP',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorForceReadyToShip']},
        {key: 'engine.state.SHIPPED', value: 'SHIPPED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorShipped']},
        {key: 'engine.state.RETURNED', value: 'RETURNED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorReturned']},
        {key: 'engine.state.MANUFACTURED', value: 'MANUFACTURED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorManufactured']},
        {key: 'componentStateBadge.name.stacked', value: 'STACKED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorStacked']},
        {key: 'componentStateBadge.name.disassembled', value: 'DISASSEMBLED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorDisassembled']},
        {key: 'componentStateBadge.name.not-shipped', value: 'NOT_SHIPPED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorMulti']},
      ]
    },
    {key: 'page.engines.table.column.engineStateMulti', value: 'engineStateList', inputType: InputType.MultiSelection,
      selection: [
        {key: 'engine.state.IN_PRODUCTION', value: 'IN_PRODUCTION',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorInProduction']},
        {key: 'engine.state.READY_TO_SHIP', value: 'READY_TO_SHIP',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorReadyToShip']},
        {key: 'engine.state.FORCED_READY_TO_SHIP', value: 'FORCED_READY_TO_SHIP',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorForceReadyToShip']},
        {key: 'engine.state.SHIPPED', value: 'SHIPPED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorShipped']},
        {key: 'engine.state.RETURNED', value: 'RETURNED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorReturned']},
        {key: 'engine.state.MANUFACTURED', value: 'MANUFACTURED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorManufactured']},
        {key: 'componentStateBadge.name.stacked', value: 'STACKED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorStacked']},
        {key: 'componentStateBadge.name.disassembled', value: 'DISASSEMBLED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorDisassembled']},
        {key: 'componentStateBadge.name.not-shipped', value: 'NOT_SHIPPED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorMulti']},
      ]
    },
    {key: 'page.engines.table.column.engineBlockedState', value: 'engineBlockedState', inputType: InputType.Selection,
      selection: [
        {key: 'engine.state.BLOCKED', value: 'BLOCKED',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorBlocked']},
        {key: 'engine.state.NONE', value: 'NONE',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorManufactured']},
      ]
    },
    // {key: 'page.engines.table.column.developmentNumber', value: 'developmentNumber', inputType: InputType.String},
    {key: 'page.engines.table.column.partNumber', value: 'partNumber', inputType: InputType.String},
    {key: 'page.engines.table.column.partNumberList', value: 'partNumber', inputType: InputType.List},
    {key: 'page.engines.table.column.engineNumberRange', value: 'engineNumber', inputType: InputType.Range },
    {key: 'i18n.component.attributes.quick-time-selection', value: 'quickTimeSelector',inputType: InputType.Selection,
      selection: Object.keys(QuickTimeSelector).map(key => (
          { key: 'i18n.component.attributes.quick-time-selection-options.' + QuickTimeSelector[key], value: QuickTimeSelector[key] }))
    }
  ];

  /**
   * TODO: Replace this with a better approach
   * The following color codings are only used on very small screens.
   * It should be replaced f.e. by making the component-state-badge working for
   * small screens although.
   */
  engineStateColorMappings = [
    { key: 'MANUFACTURED', value: 'seagreen' },
    { key: 'IN_PRODUCTION', value: 'darkgoldenrod' },
    { key: 'READY_TO_SHIP', value: 'darkturquoise' },
    { key: 'FORCED_READY_TO_SHIP', value: 'darkturquoise' },
    { key: 'SHIPPED', value: 'dodgerblue' },
    { key: 'RETURNED', value: 'coral' },
    { key: 'UNKNOWN', value: 'darkgrey' },
  ];

  public showLocationColumn = false;


  protected readonly LogisticalState = LogisticalState;
  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;
  mutuallyexclusiveAttributes: Array<Array<string>> = new Array<Array<string>>(
    new Array<string>('buildDate', 'quickTimeSelector'),
    new Array<string>('engineState', 'engineStateList'));

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.update(true);
    });
    this.setupAsyncSearchSelections();
  }

  /**
   *
   * @param interval The new interval we receive from the autorefresh component
   * This function receives the new select auto refresh interval. This interval is used to calculate
   * the "new events" coloring
   */
  public refreshComponentAutoReloadIntervalChangedEvent(interval: number) {
    this.refreshComponentAutoReloadInterval = interval ? interval : 0;
  }

  /**
   *
   * @param modifiedAt The date when the item was last modified
   * The function changes returns the color of the row. In case of new changes in the last range of time
   * the line on top gets a special color defined in the scss.
   */
  public highlightNewElements(modifiedAt: string) {
    const date = new Date(modifiedAt);
    const currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() - ((this.refreshComponentAutoReloadInterval / 1000) + 20));

    if (date >= currentDate) {
      return 'cpp-list-is-new';
    } else {
      return 'cpp-list-is-not-new';
    }
  }

  /**
   *
   * @param state The Engine State we need to set a color for
   * It returns a color which represents the state of the engine. This is only used for very small screens
   * and should be replaced by generalization of the component-state-bagde. See TODO on Top for more infos.
   */
  public getEngineStateColor(state: EngineState) {
    const mapping = this.engineStateColorMappings.find(m => m.key === state);

    if (!mapping) {
      return 'darkgrey';
    }

    return  mapping.value;
  }

  /**
   *
   * @param column The column we want to use for sorting
   * The given column will be added to the current filter (to respect current searches).
   * The settings for slice and page index will be re-set to deliver a fitting result.
   */
  public onSort(column: string) {
    if (this.listUpdating) {
      return;
    }

    this.listSort = this.listFilter.sortOn(column);

    this.listFilter.pagingSize = 50;
    this.listFilter.totalPages = 1;
    this.listFilter.currentPageIndex = 0;
    this.listFilter.slice = undefined;

    this.update(true);
  }

  /**
   *
   * @param tab Every rendered button tab on view checks if its the active one by name
   * Checks wether the current caller is the active tab.
   */
  public isActive(tab: string): boolean {
    return this.activeTab === tab;
  }

  /**
   *
   * @param tab The tab that was selected by clicking the corresponding button
   * Sets the new value as the active tab.
   */
  public setActive(tab: string) {
    this.activeTab = tab;
  }

  /**
   * This raises an excel export for the current filter. The result will be delivered into the users inbox async.
   */
  public requestFilterBasedReport() {
    this.triggerFilterBasedReport.next(this.listFilter);
  }

  /**
   * A manuel trigger to refresh the component. This triggers a reset of all filters and search.
   * It although resets the result list.
   */
  public refreshComponentWasClickedEvent() {
    this.clearFilterSub.next();
    this.resetSearchAndFilter();
    this.update(true);
  }

  /**
   * A automatic refresh was triggered by the refresh component.
   */
  public refreshComponentRefreshIntervalReachedEvent() {
    this.update(true);
  }

  /**
   * On Down is called every time the user hits the bottom of the current view-area.
   * On Update it silently reloads the next slice of data from backend.
   * Although it emits an event to disable auto-refresh of th list.
   */
  public onDown() {
    if (!this.listUpdating) {
      // When we lost focus on top of the list, we disable the auto update / auto refresh
      this.refreshComponentAutoReloadEnabled.next(false);
      this.update(false);
    }
  }

  /**
   * OnUp is fired when scrolling up and reaching the top of the table.
   * It then emits an event to re-enable auto-refresh on the list.
   */
  public onUp() {

    // On regaining focus of the top of the list, we enable the auto update / auto refresh
    this.refreshComponentAutoReloadEnabled.next(true);
  }

  /**
   * @param reset  If set to true, the reset parameter triggers
   * the reset of the result list.
   * If false, the loading spinner will stay hidden.
   * The function starts a list and count update. On finally it sets the view back to a normal state.
   */
  private update(reset: boolean = false) {
    if (this.listUpdating) {
      return;
    }
    this.listUpdating = true;
    this.countUpdating = true;
    this.refreshComponentAnimationEnabled.next(true);

    if (reset) {
      this.listFilter.currentPageIndex = 0;
    }

    this.engineService.countEnginesV3Filter(this.listFilter)
      .then(count => {
        this.count = count;
      }).finally(() => {
        this.countUpdating = false;
    });

    this.engineService.getEnginesV3Filter(this.listFilter)
      .then(response => {
        if (reset) {
          this.list = response.content.map(engineResponse => new Engine(engineResponse));
        } else{
          this.list.push(... response.content.map(engineResponse => new Engine(engineResponse)))
        }
        this.listFilter.slice = response.pageable

        this.listFilter.currentPageIndex++;

        if (response.content.length > 0) {
          this.tryGetLogisticsDataAsync(response.content);
        }
      })
      .finally(() => {
        this.listUpdating = false;

        // Searching only active when a search is active.
        // But in any case we need to set it to false after finishing the update.
        this.searching = false;

        // Stop the refresh component spinner
        this.refreshComponentAnimationEnabled.next(false);
      });
  }

  /**
   *
   * @param filterValues The value filterValues contains optional search values.
   * @private
   * The method builds up a new filter from currently set filter values or given new filter values,
   * potentially set sort options and a potentially set date range. Afterwards it triggers the list
   * update with the constructed filter.
   */
  public onSearch(filterValues?: Array<FilterValue>) {
    this.searching = true;

    if (!this.listFilter) {
      this.listFilter = new EngineFilter();
    }

    if (filterValues) {
      this.setFilterValues(filterValues);
    }

    this.applyFilterValuesToNewFilter();
    if (this.listSort !== undefined) {
      LoggingService.logDebug(LoggingSource.ENGINE_LIST, 'Set pre-existing sorting to new filter: ' + this.listSort);
      this.listFilter.sort = this.listSort;
    }

    this.update(true);
  }

  private applyFilterValuesToNewFilter() {
    this.listFilter = new EngineFilter();

    this.filterValues.forEach(filterValue => {
      if (Array.isArray(this.listFilter[filterValue.attribute])){
        if (Array.isArray(filterValue.value)){
          this.listFilter[filterValue.attribute].push(...filterValue.value)
        } else {
          this.listFilter[filterValue.attribute].push(filterValue.value)
        }
      } else {
        this.listFilter[filterValue.attribute] = filterValue.value;
      }
    });
  }

  /**
   * On Search abort, the search input, the custom sortorder
   * and the element filter are being reset to the initial state.
   * Then a re-loading with default settings is initiated.
   */
  public onSearchAbort() {
    this.resetSearchAndFilter();
    this.update(true);
  }

  private resetSearchAndFilter() {
    this.listFilter = new EngineFilter();
    this.listSort = undefined;
  }

  public setFilterValues(filterValues: Array<FilterValue>) {
    this.filterValues = filterValues;
  }

  private setupAsyncSearchSelections() {
    const costCenterFilter: CostCenterFilter = new CostCenterFilter();
    costCenterFilter.pagingSize = 500;
    this.costCenterService.getCostCenters(costCenterFilter, true).then(response => {
      const entry = {key: 'page.engines.table.column.costCenter', value: 'costCenter',
        inputType: InputType.SelectionAndString, selection: []};
      response.costCenters.sort((a, b) => a.nr.localeCompare(b.nr));
      response.costCenters.forEach(cc => entry.selection.push({key: cc.nr, value: cc.nr}));
      const newList: Array<AttributeEntry> = this.searchAliasMappings.map(val => val);
      newList.push(entry);
      newList.push({key: 'page.engines.table.column.costCenterList', value: 'costCenter', inputType: InputType.List});
      this.searchOptionSource.next(newList);
      this.searchOptionSource.complete();
    }).catch(error => {
      ErrorHandler.printError(error);

      const newList: Array<AttributeEntry> = this.searchAliasMappings.map(val => val);
      newList.push({key: 'page.engines.table.column.costCenter', value: 'costCenter', inputType: InputType.String});
      newList.push({key: 'page.engines.table.column.costCenterList', value: 'costCenter', inputType: InputType.List});
      this.searchOptionSource.next(newList);
      this.searchOptionSource.complete();
    });
  }

  public getLogisticalState(engine: EngineResponse): LogisticalState {
    if (engine.engineState === EngineState.InProduction || engine.engineState === EngineState.Disassembled) {
      return LogisticalState.UNDETERMINED;
    }


    if (engine.engineBlockedState !== 'NONE') {
      return LogisticalState.UNFREE;
    }

    if (engine.engineReworkState === 'PENDING') {
      return LogisticalState.UNFREE;
    }

    if (engine.engineTestState !== 'OK') {
      return LogisticalState.UNFREE;
    }

    if (engine.engineState === EngineState.Unknown) {
         return LogisticalState.UNFREE;
    }

    return LogisticalState.FREE;
  }

  private tryGetLogisticsDataAsync(engines: Array<EngineResponse>) {
    this.trackingService.getStacksByComponentIDList(engines.map(e => e.id ))
      .then(stacks => {
        stacks.forEach(stack => {

          stack.components.forEach(component => {
            const existingEngineIndex = this.list.findIndex(engine => engine.id === component.componentId);
            if ( existingEngineIndex !== -1 ) {
              this.list[existingEngineIndex].logisticalQuickInfo = new LogisticalQuickInfo(stack);
            }
          })
        })
      }
    )
  }

  public toggleLogisticColumVisibility() {
    this.showLocationColumn = !this.showLocationColumn;
  }
}
/**
 * Helper class to store information about the currently selected build daterange.
 */
export class ActiveBuildDateRange {
  public from: string;
  public to: string;

  constructor(from: string, to: string) {
    this.from = from;
    this.to = to;
  }
}

export enum LogisticalState {
  UNDETERMINED = 'UNDETERMINED',
  FREE = 'FREE',
  UNFREE = 'UNFREE'
}

