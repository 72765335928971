import {CppItemBase} from '../../../../shared/models/cppItemBase';

export class DmcParseResult extends CppItemBase {
  public DMC: string;
  public IsValid: boolean;

  public Partnumber: string;
  public Manufacturer: string;
  public SerialNumber: string;
  public ProductionDate: string;
  public SequenceNumber: number;
  public ProductionLine: number;

  public ExtraData: string;
}
