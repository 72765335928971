import {EventEmitter, Injectable} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {AuthService} from './auth.service';
import {ConfigService} from './config.service';
import {ApplicationControllerService} from '@cstx/volkswagen-mqs-application-management-service-client';
import {NotificationService} from '../../shared/services/backend/notification.service';
import {
  ComponentCreatedMessage,
  FaultCreatedMessage
} from '../../shared/components/websocket-connections-tester/websocket-connections-tester.component';
import {LoggingService} from '../logging/logging.service';
import {LoggingSource} from '../logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {

  public constructor(private notificationService: NotificationService,
                     private authService: AuthService,
                     private configService: ConfigService,
                     private applicationControllerService: ApplicationControllerService) {}
  public static onConnect = new EventEmitter<number>();
  public static onDisconnect = new EventEmitter<any>();
  public static onError = new EventEmitter<Event>();

  public static onMessageReceived = new EventEmitter<WebSocketMessage>();

  public static componentsCreated = new Subject<ComponentCreatedMessage>();
  public static faultyComponentCreated = new Subject<FaultCreatedMessage>();


  public static connected = false;
  public static connectedAt: Date;

  public webSocketClients = new Array<string>();
  public webSocketClientsUpdating =  false;

  private socket: WebSocket;

  private initTimeout: NodeJS.Timer;
  private healthCheckTimout: NodeJS.Timer;
  private pingTimout: NodeJS.Timer;

  private connectionCounter = 0;

  public send(data: string) {
    try {
      this.socket.send(data);
    } catch (error) {
      console.log('catched websocket error');
      console.log(error);
    }

  }

  public close() {
    this.socket.close();
  }


  public async open() {
    if (this.authService.isApplicationUser) {
      firstValueFrom(this.applicationControllerService.getSelf({})).then(res => {
        if ( res.scopes.findIndex(scope => scope.service === 'NOTIFICATION_SERVICE' && scope.canRead ) > -1 ) {
          this.tryInitWebSocket();
        }
      });
    } else {
      await this.tryInitWebSocket();
    }

    this.getActiveWebSocketClients();
    setInterval(() => {
      this.getActiveWebSocketClients();
    }, 5000 )
  }

  public getActiveWebSocketClients() {
    if (this.webSocketClientsUpdating) {
      return;
    }

    this.webSocketClientsUpdating = true;

    this.notificationService
      .getWebSocketClients()
      .then(response => {
      if (response) {

        response
          .sort((a, b) => a.localeCompare(b));

        this.webSocketClients = response;
      }

        this.webSocketClientsUpdating = false;

      });
  }

  private async tryInitWebSocket() {
    if (this.socket && this.socket.readyState === 1) {
      LoggingService.logWarning(LoggingSource.OTHER, `Websocket connection already established.`);
      return;
    }


    clearInterval(this.healthCheckTimout);

    const token = await this.notificationService.getWebSocketToken()
    if (!token) {
      LoggingService.logWarning(LoggingSource.OTHER, 'Could not retrieve websocket token. Retrying in 5 seconds...');

      clearTimeout(this.initTimeout);
      this.initTimeout = setTimeout(async () => {
        await this.tryInitWebSocket();
      }, 5000);

      return;
    }

    const nnsUrl = this.configService.getNnsApiUrl().replace('http', 'ws') + '/ws';
    LoggingService.logWarning(LoggingSource.OTHER, `Try opening websocket at [${nnsUrl}].`);

    this.socket = new WebSocket(nnsUrl);

    this.initTimeout = setTimeout(() => {
      /**
       * This timeout gets canceled, when the websocket
       * object receives an open event.
       */

      LoggingService.logWarning(LoggingSource.OTHER, 'Could not open websocket in time. Retrying in 5 seconds...');
      this.tryInitWebSocket();

      return;
    }, 5000);

    this.socket.onopen = () => {
      clearTimeout(this.initTimeout);
      this.handleSocketOpen(token);
      this.runSocketHealthCheck();
    }

    this.socket.onclose = closeEvent => {
      this.handleSocketClose();
    };

    this.socket.onmessage = event => {
      let message: WebSocketMessage;
          message = JSON.parse(event.data);

      this.processMessage(message);
    };

    this.socket.onerror = event => {
      this.handleSocketError(event);
    }

    this.pingSocket();
  }

  private handleSocketOpen(token: string) {
    this.connectionCounter = this.connectionCounter + 1;
    LoggingService.logDebug(LoggingSource.OTHER, 'Websocket connection opened...');

    const initializationMessage = this.authService.currentUser?.email + '_' + token;
    this.send(initializationMessage);

    WebsocketsService.connected = true;
    WebsocketsService.connectedAt = new Date();

    WebsocketsService.onConnect.emit(this.connectionCounter);
    LoggingService.logDebug(LoggingSource.OTHER, 'Websocket connection initiated...');
  }

  private runSocketHealthCheck() {
    /**
     * This interval checks for the websocket ready state.
     * If the ready state changes to anything else then 1, it should initiate a reconnect.
     */
    LoggingService.logDebug(LoggingSource.OTHER, 'Websocket connection watcher starting...')

    clearInterval(this.healthCheckTimout);
    this.healthCheckTimout = setInterval(async () => {
      if (this.socket.readyState !== 1) {
        LoggingService.logWarning(LoggingSource.OTHER, 'Websocket connection state change to something else then 1... Try reconnecting ...');
        await this.tryInitWebSocket();
      } else {
        LoggingService.logDebug(LoggingSource.OTHER, 'Websocket connection is active...')
      }
    }, 3000);
  }

  private handleSocketClose() {
    LoggingService.logError(LoggingSource.OTHER, 'Websocket connection was closed ...');

    WebsocketsService.connected = false;
    WebsocketsService.connectedAt = undefined;
    WebsocketsService.onDisconnect.emit();
  }

  private handleSocketError(event: any) {
    LoggingService.logError(LoggingSource.OTHER, 'Websocket connection error occurred!');
    WebsocketsService.onError.emit(event);
  }

  private pingSocket() {
    /**
     * This is currently to prevent self shutdown of the connection
     * as the used server has ping/pong handler function, there could be a better
     * way of achieving this.
     */
    clearInterval(this.pingTimout);
    this.pingTimout = setInterval(() => {
      if (this.socket.readyState === 1) {
        LoggingService.logDebug(LoggingSource.OTHER, 'Sending PING to websocket connection...')
        this.send('ping_' + this.authService.currentUser?.email);
      }
    }, 5000);
  }

  private processMessage(message: WebSocketMessage) {
    WebsocketsService.onMessageReceived.emit(message);

    if (message.subject === 'ComponentCreated') {
      const msg = new ComponentCreatedMessage(message);

      WebsocketsService.componentsCreated.next(msg);
    }

    if (message.subject === 'FaultCreated') {
      const msg = new FaultCreatedMessage(message);

      WebsocketsService.faultyComponentCreated.next(msg);
    }
  }
}

export class WebSocketMessage {
  public subject?: string;
  public message?: string;
  public origin?: string;
  public receivedAt?: string;

  public isNew = false;
}
