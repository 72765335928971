import {Injectable} from '@angular/core';
import {
  ComponentInfoResponse,
  ComponentType,
  ParseDMCService,
  ParseXmlControllerV2Service
} from "@cstx/volkswagen-mqs-parsing-service-client";
import {firstValueFrom} from "rxjs";
import {LoggingService} from "../../../core/logging/logging.service";
import {LoggingSource} from "../../../core/logging/loggingSource";
import {DmcParseResult} from "../../../modules/tools/dmc/parse/dmcParseResult";
import {DMCResult} from "../../components/dmc-parser/models/dmcResult";

@Injectable({
  providedIn: 'root'
})
export class ParsingService {

  constructor(private parseXmlControllerV2Service: ParseXmlControllerV2Service,
              private parseDMCService: ParseDMCService) { }

  /**
   * Returns the results of parsing from all xml files found to a given component id.
   * @param componentId The id of the component we want to parse xml for.
   * @param componentType The type of the component.
   */
  public async parseXmlV2(componentId: string, componentType: ComponentType): Promise<ComponentInfoResponse[]> {
    try {
      return await firstValueFrom(this.parseXmlControllerV2Service.apiV2ParseXmlGet({
        componentId,
        componentType
      }));

    } catch (error) {
      LoggingService.logError(LoggingSource.PARSING_SERVICE,
        `Error occurred when parsing xml files of component with id [${componentId}].`, error)
    }

    return null;
  }


  public async getDmcDetails(dmc: string): Promise<DmcParseResult> {
    if (dmc === '') {
      return null;
    }

    try {
      const result = await firstValueFrom(this.parseDMCService.parseDmcPost({ dmc }));

      return this.mapDMCResult(result);
    } catch (error) {

    }

    return null;
  }

  private mapDMCResult(result: DMCResult): DmcParseResult {
    const r = new DmcParseResult();

    r.DMC = result.dmc;
    r.Manufacturer = result.manufacturerCode.value;
    r.Partnumber = result.partNumber.value;
    r.ProductionDate = result.productionDate.value;
    r.ProductionLine = result.productionLine.value;
    r.SequenceNumber = result.sequenceNumber.value;
    r.SerialNumber = result.serialNumber.value;
    return r;
  }
}
