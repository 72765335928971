<div class="pt-3 pb-3">
  <h6> {{ 'i18n.component.reworks.create-title' | translate }}</h6>
  <small>
    {{ 'i18n.component.reworks.create-description' | translate }}
  </small>
</div>

<div class="row pl-3">
  <div class="col">
    <div class="row">
      <div class="col">
        <div>
          <input class="form-check-input" type="radio" name="inlineRework" id="inlineRework" (change)="onReworkTypeChanged()" [checked]="intern">
          <label class="form-check-label" for="inlineRework">
            {{ 'i18n.component.reworks.inline' | translate }}
          </label>
        </div>
        <div>
          <input class="form-check-input" type="radio" id="offlineRework" name="offlineRework" (change)="onReworkTypeChanged()" [checked]="!intern">
          <label class="form-check-label" for="offlineRework">
            {{ 'i18n.component.reworks.offline' | translate }}
          </label>
        </div>
      </div>
      </div>

    <div class="row pt-3">
      <div class="col small">
        {{ 'i18n.component.reworks.intern-extern-hint' | translate }}
      </div>
    </div>
    </div>
  <div class="col">
    <div>
      <h6>{{ 'i18n.component.reworks.complaint' | translate}}</h6>
    </div>
    <input
      [class.is-invalid]="complaint.length < 1 || complaint.length > 255"
      [(ngModel)]="complaint"
      class="form-control"
      aria-describedby="complaintHelp"
      type="text"
      required>
      <op-form-help-text [formControlInvalid]="complaint.length < 1 || complaint.length > 255" ariaId="complaintReasonHelp" helpText="i18n.component.reworks.complaint-help"></op-form-help-text>
</div>
  <div class="col">
<div class="row pl-3" style="padding-top: 1.9rem">
  <div class="col text-left">
    <button class="btn btn-sm btn-secondary mr-3" (click)="cancel()"
            [disabled]="creatingRework">{{ 'button.cancel' | translate }}
    </button>

    <button class="btn btn-sm btn-primary"
            (click)="save()"
            [disabled]="creatingRework || complaint.length < 1 || complaint.length > 255">
      {{ 'button.save' | translate }}
    </button>

    <div class="d-inline pl-3" *ngIf="creatingRework">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </div>
</div>

  <div class="row pl-3 pt-1">
    <div class="col text-left small">
      <input type="checkbox" class="pl-1" [(ngModel)]="goToNewRework">
      {{ 'i18n.component.reworks.browse-to-rework' | translate }}
    </div>
  </div>
  </div>
</div>
