<op-detail-section-wrapper
  [title]="'component-profile.favorites' | translate"
  [description]=""
  [contentTemplate]="contentTemplateRef"
  [hasContent]="true"
  [collapsable]="false">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <tr class="row" style="margin-top: -15px;">
  <ul class="nav">
  <li class="nav-item">
    <a class="pl-1" [routerLink]="['.']" [fragment]="'attachments'">
      <button (click)="emitEvent('attachments')" id="attachmentButton" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'file-explorer.title' | translate }}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'assembly'">
      <button (click)="emitEvent('assembly')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'assembly-detail.title' | translate }}</button>
    </a>
    <ng-container *ngIf="parentComponentId">
    <a class="pl-1" [routerLink]="['.']" [fragment]="'parent-assembly'">
      <button (click)="emitEvent('parent-assembly')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'assembly-detail.parent.title' | translate }}</button>
    </a>
    </ng-container>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'approval'">
      <button (click)="emitEvent('approval')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'component-approval-v2.title' | translate}}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'logistics'">
      <button (click)="emitEvent('logistics')" type="button" class="btn btn-sm btn-outline-dark mt-1"> {{ 'component-profile.logistics.title' | translate }}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'tests'">
      <button (click)="emitEvent('tests')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'component-profile.tests.title' | translate }}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'reworks'">
      <button (click)="emitEvent('reworks')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'component-profile.reworks.title' | translate }}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'blockings'">
      <button (click)="emitEvent('blockings')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'component-profile.blockings.title' | translate }}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'process'">
      <button (click)="emitEvent('process')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'manufactoring-process.label.processData' | translate}}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'measurements'">
      <button (click)="emitEvent('measurements')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'component-profile.measurements.title' | translate}}</button>
    </a>
      <a class="pl-1" [routerLink]="['.']" [fragment]="'events'">
        <button (click)="emitEvent('events')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'component-profile-v2.events.title' | translate }}</button>
      </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'telegrams'">
      <button (click)="emitEvent('telegrams')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'telegram.title' | translate }}</button>
    </a>
    <a class="pl-1" [routerLink]="['.']" [fragment]="'refresh'">
      <button (click)="refreshComponent()" type="button" class="btn btn-sm btn-outline-info mt-1">{{ 'button.refresh' | translate }}</button>
    </a>

    <ng-container *ngIf="componentType === 'ENGINE'">
      <ng-container *opRequiredClaimByName="'engine-label-generate'">
    <a class="pl-1"  [routerLink]="['.']" [fragment]="'actions'">
      <button (click)="emitEvent('actions', ComponentProfileActionType.PRINT_LABEL)" type="button" class="btn btn-sm btn-outline-info mt-1">{{ 'component-profile-v2.actions.reprintLabel.title' | translate }}</button>
    </a>
    <a class="pl-1" *opRequiredClaimByName="'engine-label-keycode-change-and-print'" [routerLink]="['.']" [fragment]="'actions'">
      <button (click)="emitEvent('actions', ComponentProfileActionType.CHANGE_KEYCODE)" type="button" class="btn btn-sm btn-outline-info mt-1">{{ 'component-profile-v2.actions.keyCodeChange.title' | translate }}</button>
    </a>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="hasBlockings">
    <ng-container *opRequiredClaimByName="'action-blockings-release'">
      <a class="pl-1" [routerLink]="['.']" [fragment]="'actions'">
        <button
          (click)="emitEvent('actions', ComponentProfileActionType.RELEASE)"
          type="button"
          class="btn btn-sm btn-outline-success mt-1">
            {{ 'scan-component.release-block' | translate }}
        </button>
      </a>
    </ng-container>
    </ng-container>

    <ng-container *opRequiredClaimByName="'action-blockings-create'">
      <a class="pl-1" [routerLink]="['.']" [fragment]="'actions'">
        <button
          (click)="emitEvent('actions', ComponentProfileActionType.BLOCK)"
          type="button"
          class="btn btn-sm btn-outline-danger mt-1">
          {{ 'scan-component.create-block' | translate }}
        </button>
      </a>
    </ng-container>

    <!-- TODO: Fix permissions -->
    <ng-container *opRequiredClaimByName="'action-blockings-create'">
      <a class="pl-1" [routerLink]="['.']" [fragment]="'create-rework'">
        <button
          (click)="emitEvent('actions', ComponentProfileActionType.CREATE_REWORK)"
          type="button"
          class="btn btn-sm btn-outline-primary mt-1">
          {{ 'scan-component.create-rework' | translate }}
        </button>
      </a>
    </ng-container>


    <ng-container *ngIf="componentType === 'ENGINE'">
      <a class="pl-1" [routerLink]="['.']" [fragment]="'keycodeHistory'">
        <button (click)="emitEvent('keycodeHistory')" type="button" class="btn btn-sm btn-outline-dark mt-1">
          {{ 'component-profile-v2.keycode-history.title' | translate }}</button>
      </a>
      <a class="pl-1" [routerLink]="['.']" [fragment]="'offSets'">
        <button (click)="emitEvent('offSets')" type="button" class="btn btn-sm btn-outline-dark mt-1">
          {{ 'component-profile-v2.offSets.title' | translate }}</button>
      </a>
    </ng-container>
    <a *opRequiredRole="'role_admin'" class="pl-1" [routerLink]="['.']" [fragment]="'dlq'">
      <button (click)="emitEvent('dlq')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'component-profile-v2.dlq.title' | translate }}</button>
    </a>
      <a class="pl-1" [routerLink]="['.']" [fragment]="'audit'">
        <button (click)="emitEvent('audit')" type="button" class="btn btn-sm btn-outline-dark mt-1">{{ 'audit-v2.title' | translate }}</button>
      </a>
    </li>
</ul>
  </tr>
</ng-template>
