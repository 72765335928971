import { ReworkState, ReworkType, Source} from '@cstx/volkswagen-mqs-engine-service-client';

export class Rework {
  isItemModeType = true;
  analysis?: string;
  complaint?: string;
  createdAt?: string;
  createdBy?: string;
  deliveredAt: string;
  dispatchedAt: string | null;
  employee: string;
  id: string;
  modifiedAt?: string;
  modifiedBy?: string;
  qualityAssuranceCheckedAt?: string | null;
  qualityAssuranceEmployee: string | null;
  rework?: string;
  reworkState?: ReworkState | null;
  reworkType?: ReworkType | null;
  signature?: string;
  source: Source;
  details = new Array<KeyValuePair>();
  public chargeTo?: string;
  public minutes?: string;

}

interface KeyValuePair {
  key: string;
  value: string;
}
