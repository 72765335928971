import {Component, HostListener, Input, OnInit} from '@angular/core';
import {EngineControllerService, ReworkResponse} from '@cstx/volkswagen-mqs-engine-service-client';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {TranslateService} from '@ngx-translate/core';
import {ComponentProfile} from '../component-profile.component';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {Rework} from './rework';
import {PartControllerV2Service} from '@cstx/volkswagen-mqs-part-service-client';
import {ComponentType} from '../component-type';

@Component({
  selector: 'op-reworks',
  templateUrl: './reworks.component.html',
  styleUrls: ['./reworks.component.scss']
})
export class ReworksComponent implements OnInit {

  constructor(private engineController: EngineControllerService,
              private partController: PartControllerV2Service,
              private breakpointObserver: BreakpointObserver,
              private translate: TranslateService) { }

  @Input() componentProfile: ComponentProfile;
  @Input() selfLink: string;
  @Input() contentContainerId: string;
  @Input() version2 = false;


  public reworks = new Array<ReworkResponse>();
  public loadingReworks = false;

  items = new Array<Rework>();
  viewSizeXS: boolean;

  treeViewItems = new Array<TreeviewItem>();
  treeViewConfig: TreeviewConfig;

  dateTimeFields = [ 'deliveredAt', 'dispatchedAt', 'qualityAssuranceCheckedAt' ];

  private beforePrintCollapsed: Map<TreeviewItem,boolean> = new Map<TreeviewItem, boolean>();

  private static createTreeViewConfig() {
    return    TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 0
    });
  }

  private static mapToItemModel(item: ReworkResponse): Rework {
    const r = new Rework();

    r.isItemModeType = true;
    r.id = item.id;
    r.analysis = item.analysis;
    r.complaint = item.complaint;
    r.createdAt = item.createdAt;
    r.createdBy = item.createdBy;
    r.deliveredAt = item.deliveredAt;
    r.dispatchedAt = item.dispatchedAt;
    r.employee = item.employee;
    r.modifiedAt = item.modifiedAt;
    r.modifiedBy = item.modifiedBy;
    r.qualityAssuranceCheckedAt = item.qualityAssuranceCheckedAt;
    r.qualityAssuranceEmployee = item.qualityAssuranceEmployee;
    r.rework = item.rework;
    r.reworkState = item.reworkState;
    r.reworkType = item.reworkType;
    r.signature = item.signature;
    r.source = item.source;
    r.chargeTo = item.chargeTo;
    r.minutes = item.minutes;


    r.details.push({ key: 'employee', value: r.employee ? r.employee : '' });
    r.details.push({ key: 'signature', value: r.signature ? r.signature : ''  });
    r.details.push({ key: 'complaint', value: r.complaint ? r.complaint : ''  });
    r.details.push({ key: 'analysis', value: r.analysis ? r.analysis : ''  });
    r.details.push({ key: 'rework', value: r.rework ? r.rework : ''  });
    r.details.push({ key: 'deliveredAt', value: r.deliveredAt ? r.deliveredAt : ''  });
    r.details.push({ key: 'dispatchedAt', value: r.dispatchedAt ? r.dispatchedAt : ''  });
    r.details.push({ key: 'chargeTo', value: r.chargeTo ? r.chargeTo : ''  });
    r.details.push({ key: 'minutes', value: r.minutes ? r.minutes : ''  });
    r.details.push({ key: 'source', value: r.source ? r.source: ''  });
    r.details.push({ key: 'qualityAssuranceCheckedAt', value: r.qualityAssuranceCheckedAt ? r.qualityAssuranceCheckedAt : '' });
    r.details.push({ key: 'qualityAssuranceEmployee', value: r.qualityAssuranceEmployee ? r.qualityAssuranceEmployee : '' });

    return r;
  }
  @HostListener('window:beforeprint')
  private onBeforePrint() {
    this.beforePrintCollapsed.clear();
    this.copyTreviewStateAndExpandAll(this.treeViewItems);
  }

  @HostListener('window:afterprint')
  private onAfterPrint() {
    this.recoverTreviewState(this.treeViewItems);
  }

  private copyTreviewStateAndExpandAll(items: Array<TreeviewItem>) {
    items.forEach(item => {
      this.beforePrintCollapsed.set(item, item.collapsed);
      item.collapsed = false;
      if (item.children && item.children.length > 0) {
        this.copyTreviewStateAndExpandAll(item.children);
      }
    })
  }

  private recoverTreviewState(items: Array<TreeviewItem>) {
    items.forEach(item => {
      const previousValue = this.beforePrintCollapsed.get(item);
      item.collapsed = previousValue === undefined ? true : previousValue
      if (item.children && item.children.length > 0) {
        this.recoverTreviewState(item.children);
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeXS = true;
        } else {
          this.viewSizeXS = false;
        }
      });

    this.treeViewConfig = ReworksComponent.createTreeViewConfig();

    await this.getReworks(this.componentProfile.Id);
  }

  private populateTree() {
    this.items.forEach(t => {
      const subItem = new TreeviewItem({text: t.deliveredAt ? t.deliveredAt : '', value: t});

      subItem.children = this.createTestResultSubItems(t);
      subItem.collapsed = true;

      this.treeViewItems.push(subItem);
    });
  }



  private createTestResultSubItems(t: Rework): TreeviewItem[] {
    let items: TreeviewItem[];

    t.details.forEach((item, index) => {
      if (index === 0) {
        items = new Array<TreeviewItem>();
      }

      const tryTranslateKey = this.translate.instant('component-profile.reworks.' + item.key);

      if (this.dateTimeFields.includes(item.key)) {
        this.dateTimeFields.push(tryTranslateKey);
      }

      items.push(new TreeviewItem({text: tryTranslateKey === 'component-profile.reworks.' + item.key ?
          item.key : tryTranslateKey, value: item.value}));
    });

    return items;
  }

  private async getReworks(id: string) {
    this.loadingReworks = true;
    if (this.componentProfile.componentType !== ComponentType.ENGINE) {
      return;
    }


    this.engineController.getReworks({ id }).toPromise()
      .then(response => {
        this.reworks = response;

        response.forEach(item => {
          this.items.push(ReworksComponent.mapToItemModel(item));
        });


        this.items.sort((a, b) => {
          if (a.deliveredAt > b.deliveredAt) {
            return -1;
          } else {
            return 1;
          }
        });

        this.populateTree();
      })
      .finally(() => this.loadingReworks = false);
  }
}
