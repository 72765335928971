import {Injectable} from '@angular/core';
import {
  BillOfMaterialControllerService,
  BlockingActionResponse,
  EngineControllerService,
  EngineControllerV2Service,
  EngineControllerV3Service,
  EngineResponse,
  GetAllOrderStatesByOrderNumberListRequestParams,
  GetPartNumberMultiLineUsageRequestParams,
  PartNumberDefinitionControllerService,
  PartNumberMultiUseInfo,
  QuickTimeSelector, ReworkControllerService,
  ReworkResponse, ReworkState, ReworkType, ReworkUpdateRequest, SliceReworkResponse,
  StatusControllerService
} from '@cstx/volkswagen-mqs-engine-service-client';
import {firstValueFrom} from 'rxjs';
import {
  OrderStateRequest
} from '@cstx/volkswagen-mqs-engine-service-client/model/orderStateRequest';
import {OrderState} from './models/orderState';
import {ErrorHandler} from '../error-handler/error-handler';
import {
  GetEnginesV3RequestParams
} from '@cstx/volkswagen-mqs-engine-service-client/api/engineControllerV3.service';
import {EngineState} from '@cstx/volkswagen-mqs-engine-service-client/model/engineState';
import {
  EngineBlockedState
} from '@cstx/volkswagen-mqs-engine-service-client/model/engineBlockedState';
import {
  SliceEngineResponse
} from '@cstx/volkswagen-mqs-engine-service-client/model/sliceEngineResponse';
import {EngineFilter} from '../../../modules/engine/list/enginefilter';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {
  PartNumberDefinitionResponse
} from '@cstx/volkswagen-mqs-engine-service-client/model/partNumberDefinitionResponse';
import {BackendService} from './models/backend-service';
import {ConfigService} from '../../../core/services/config.service';
import {BackendStatus} from './models/BackendStatus';
import {
  GetDistinctCostCenterKeyCodeCombinationsRequestParams
} from '@cstx/volkswagen-mqs-engine-service-client/api/billOfMaterialController.service';
import {ReworkFilter} from './models/filter/rework-filter';
import {CreateReworkRequestParams} from "@cstx/volkswagen-mqs-engine-service-client/api/engineController.service";
import {ReworkCreateRequest} from "@cstx/volkswagen-mqs-engine-service-client/model/reworkCreateRequest";
import {AuthService} from "../../../core/services/auth.service";
import {UpdateReworkRequestParams} from "@cstx/volkswagen-mqs-engine-service-client/api/reworkController.service";

@Injectable({
    providedIn: 'root'
})
export class EngineService extends BackendService {

    constructor(private engineControllerServiceV2: EngineControllerV2Service,
                private engineControllerServiceV3: EngineControllerV3Service,
                private billOfMaterialService: BillOfMaterialControllerService,
                private engineControllerService: EngineControllerService,
                private enginePartNumberDefinitionController: PartNumberDefinitionControllerService,
                private statusControllerService: StatusControllerService,
                private reworkControllerService: ReworkControllerService) {
      super('engine-service', ConfigService.configuration.eesApiUrl);
    }

    public async getEnginesV3Filter(filter: EngineFilter): Promise<SliceEngineResponse> {
        return this.getEnginesV3(
            filter.componentName,
            undefined,
            filter.engineState,
            filter.engineStateList,
            filter.engineCode,
            filter.engineNumber,
            filter.engineNumberFrom,
            filter.engineNumberTo,
            filter.partNumber,
            undefined,
            undefined,
            undefined,
            filter.keyCode,
            filter.costCenter,
            filter.buildDateFrom,
            filter.buildDateTo,
            filter.engineIds,
            filter.engineBlockedState,
            filter.quickTimeSelector,
            filter.currentPageIndex,
            filter.pagingSize,
            filter.sort,
            filter.humanReadableId)
    }

  public async getStatus(): Promise<BackendStatus> {
    let backendStatus: BackendStatus = BackendStatus.UNKNOWN;

    try {
      const response = await firstValueFrom(this.statusControllerService.getStatus());

      if (response.status.toString() === 'OK/Running') {
        backendStatus = BackendStatus.UP;
      } else {
        backendStatus = BackendStatus.FAILED;
      }
    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying get status from service.`, error);
    }

    return backendStatus;
  }

    public async getEnginesV3(componentName?: string,
                              timingBeltLabel?: string,
                              engineState?: EngineState,
                              engineStateList?: Array<EngineState>,
                              engineCodes?: Array<string>,
                              engineNumber?: Array<string>,
                              engineNumberFrom?: string,
                              engineNumberTo?: string,
                              partNumbers?: Array<string>,
                              developmentNumber?: string,
                              lotNumber?: string,
                              orderNumber?: string,
                              keyCodes?: Array<string>,
                              costCenters?: Array<string>,
                              buildDateFrom?: string,
                              buildDateTo?: string,
                              engineIDs?: Array<string>,
                              engineBlockedState?: EngineBlockedState,
                              quickTimeSelector?: QuickTimeSelector,
                              page?: number,
                              size?: number,
                              sort?: Array<string>,
                              humanReadableId?: string): Promise<SliceEngineResponse> {

        const mappedFilterInput: GetEnginesV3RequestParams = {
            componentName: this.nullOrUndefinedToUndefined(componentName),
            timingBeltLabel: this.nullOrUndefinedToUndefined(timingBeltLabel),
            engineState: this.nullOrUndefinedToUndefined(engineState),
            engineStateList: this.nullOrUndefinedToUndefined(engineStateList),
            engineCodes: this.nullOrUndefinedToUndefined(engineCodes),
            engineNumber: this.nullOrUndefinedToUndefined(engineNumber),
            engineNumberFrom: this.nullOrUndefinedToUndefined(engineNumberFrom),
            engineNumberTo: this.nullOrUndefinedToUndefined(engineNumberTo),
            partNumbers: this.nullOrUndefinedToUndefined(partNumbers),
            developmentNumber: this.nullOrUndefinedToUndefined(developmentNumber),
            lotNumber: this.nullOrUndefinedToUndefined(lotNumber),
            orderNumber: this.nullOrUndefinedToUndefined(orderNumber),
            keyCodes: this.nullOrUndefinedToUndefined(keyCodes),
            costCenters: this.nullOrUndefinedToUndefined(costCenters),
            buildDateFrom: this.nullOrUndefinedToUndefined(buildDateFrom),
            buildDateTo: this.nullOrUndefinedToUndefined(buildDateTo),
            engineIDs: this.nullOrUndefinedToUndefined(engineIDs),
            engineBlockedState: this.nullOrUndefinedToUndefined(engineBlockedState),
            humanReadableId: this.nullOrUndefinedToUndefined(humanReadableId),
            quickTimeSelector: this.nullOrUndefinedToUndefined(quickTimeSelector),
            page: this.nullOrUndefinedToUndefined(page),
            size: this.nullOrUndefinedToUndefined(size),
            sort: this.nullOrUndefinedToUndefined(sort)
        }

        return await firstValueFrom(this.engineControllerServiceV3.getEnginesV3(mappedFilterInput))

    }

    public nullOrUndefinedToUndefined(val) {
        return val === null || val === undefined ? undefined : val
    }

    public async countEnginesV3Filter(filter: EngineFilter): Promise<number> {
        return this.countEnginesV3(undefined,
            undefined,
            filter.engineState,
            filter.engineStateList,
            filter.engineCode,
            filter.engineNumber,
            filter.engineNumberFrom,
            filter.engineNumberTo,
            filter.partNumber,
            undefined,
            undefined,
            undefined,
            filter.keyCode,
            filter.costCenter,
            filter.buildDateFrom,
            filter.buildDateTo,
            undefined,
            filter.engineBlockedState,
            filter.quickTimeSelector,
            filter.humanReadableId,
            filter.currentPageIndex,
            filter.pagingSize)

    }

    public async countEnginesV3(componentName?: string,
                                timingBeltLabel?: string,
                                engineState?: EngineState,
                                engineStateList?: Array<EngineState>,
                                engineCodes?: Array<string>,
                                engineNumber?: Array<string>,
                                engineNumberFrom?: string,
                                engineNumberTo?: string,
                                partNumbers?: Array<string>,
                                developmentNumber?: string,
                                lotNumber?: string,
                                orderNumber?: string,
                                keyCodes?: Array<string>,
                                costCenters?: Array<string>,
                                buildDateFrom?: string,
                                buildDateTo?: string,
                                engineIDs?: Array<string>,
                                engineBlockedState?: EngineBlockedState,
                                quickTimeSelector?: QuickTimeSelector,
                                humanReadableId?:string,
                                page?: number,
                                size?: number,
                                sort?: Array<string>): Promise<number> {
        const mappedFilterInput: GetEnginesV3RequestParams = {
            timingBeltLabel: this.nullOrUndefinedToUndefined(timingBeltLabel),
            engineState: this.nullOrUndefinedToUndefined(engineState),
            engineStateList: this.nullOrUndefinedToUndefined(engineStateList),
            engineCodes: this.nullOrUndefinedToUndefined(engineCodes),
            engineNumber: this.nullOrUndefinedToUndefined(engineNumber),
            engineNumberFrom: this.nullOrUndefinedToUndefined(engineNumberFrom),
            engineNumberTo: this.nullOrUndefinedToUndefined(engineNumberTo),
            partNumbers: this.nullOrUndefinedToUndefined(partNumbers),
            developmentNumber: this.nullOrUndefinedToUndefined(developmentNumber),
            lotNumber: this.nullOrUndefinedToUndefined(lotNumber),
            orderNumber: this.nullOrUndefinedToUndefined(orderNumber),
            keyCodes: this.nullOrUndefinedToUndefined(keyCodes),
            costCenters: this.nullOrUndefinedToUndefined(costCenters),
            buildDateFrom: this.nullOrUndefinedToUndefined(buildDateFrom),
            buildDateTo: this.nullOrUndefinedToUndefined(buildDateTo),
            engineIDs: this.nullOrUndefinedToUndefined(engineIDs),
            engineBlockedState: this.nullOrUndefinedToUndefined(engineBlockedState),
            humanReadableId: this.nullOrUndefinedToUndefined(humanReadableId),
            quickTimeSelector: this.nullOrUndefinedToUndefined(quickTimeSelector),
            page: this.nullOrUndefinedToUndefined(page),
            size: this.nullOrUndefinedToUndefined(size),
            sort: this.nullOrUndefinedToUndefined(sort)
        }
        return await firstValueFrom(this.engineControllerServiceV3.getEnginesCountV3(mappedFilterInput))
    }


    public async getOrderStatesByOrderNumberList(orderNumbers: Array<string>): Promise<OrderState[]> {
        let request: OrderStateRequest;
        request = {
            orderNumbers
        }
        let params: GetAllOrderStatesByOrderNumberListRequestParams;
        params = {
            orderStateRequest: request
        }

        const response = await firstValueFrom(this.engineControllerService.getAllOrderStatesByOrderNumberList(params));
        return response;
    }

    /**
     * This method returns a engine for the given id.
     * @param id The id we want to get engine information for.
     * @return Promise<EngineResponse> A EngineResponse object or null.
     */
    public async getEngineById(id: string): Promise<EngineResponse> {
        let result: EngineResponse = null;

        try {
            const response = await firstValueFrom(this.engineControllerService.getEngineById({id}));
            result = response;
        } catch (error) {
            ErrorHandler.printError(error);
        }

        return result;
    }


    /**
     * This method returns an engine list for the given ids.
     * @param list The ids we want to get assembly information for.
     * @param pagingSize Size of the pages to get. Default 250.
     * @return Promise<Array<EngineResponse>> A engine object array or an empty list.
     */
    public async getEnginesByIDList(list: string[], pagingSize: number = 250): Promise<Array<EngineResponse>> {
        let response = new Array<EngineResponse>();
        let allRequested = false;


        try {
            while (!allRequested) {
                let toGet: string[];

                if (list.length > 0) {
                    toGet = list.splice(0, pagingSize);

                    const result = await firstValueFrom(this.engineControllerService.getAllEnginesByIDList({
                        engineListRequest: {
                            ids: toGet
                        }
                    }));

                    if (response.length === 0) {
                        response = result;
                    } else {
                        response.push(...result);
                    }

                } else {
                    allRequested = true;
                }
            }
        } catch (error) {
            ErrorHandler.printError(error);
        }

        return response;
    }


    /**
     * This method returns a single engine (first found) based on a given timingBelt label value.
     * It is basically a simple wrapper around getEnginesV2().
     * @param timingBeltLabel The timingBeltLabel we want to find the engine for.
     */
    public async getEngineByTimingBeltLabel(timingBeltLabel: string): Promise<EngineResponse> {
        let result: EngineResponse = null;

        const filter = new EngineFilter();
        filter.timingBeltLabel = timingBeltLabel;

        const response = await this.getEnginesV2(filter);

        if (response != null && response.content.length >= 1) {
            result = response.content[0]
        }

        return result;
    }

    /**
     * This method returns a single engine (first found) based on a given componentName.
     * It is basically a simple wrapper around getEnginesV2().
     * @param componentName The componentName we want to search for.
     */
    public async getEngineByComponentName(componentName: string): Promise<EngineResponse> {
        let result: EngineResponse;

        try {
          const filter = new EngineFilter();

          const engineCode = componentName.substring(0, 3);
          filter.engineCode.push(engineCode);

          const engineNumber = componentName.substring(4, 10);
          filter.engineNumber.push(engineNumber);

          const response = await this.getEnginesV2(filter);

          if (response && response.content?.length >= 1) {
            result = response.content[0]
          }
        } catch (e) {
          result = null;
        }
      return result;
    }

    /**
     * This method returns a single engine (first found) based on a given componentName.
     * It is basically a simple wrapper around getEnginesV2().
     * @param list
     * @param pagingSize
     */
    public async getEngineByComponentNameList(list: string[], pagingSize: number = 250): Promise<EngineResponse[]> {
        let response = new Array<EngineResponse>();
        let allRequested = false;


        try {
            while (!allRequested) {
                let toGet: string[];

                if (list.length > 0) {
                    toGet = list.splice(0, pagingSize);

                    const result = await firstValueFrom(this.engineControllerService.getAllEnginesByComponentNameList({
                        engineComponentNameListRequest: {
                            componentNames: toGet
                        }
                    }));

                    if (response.length === 0) {
                        response = result;
                    } else {
                        response.push(...result);
                    }

                } else {
                    allRequested = true;
                }
            }
        } catch (error) {
            ErrorHandler.printError(error);
        }

        return response;
    }


    /**
     * This method returns all engines based on a given filter.
     * @param filter The engineFilter we use to find the engines
     */

    public async getEnginesV2(filter: EngineFilter): Promise<SliceEngineResponse> {
        let result: SliceEngineResponse = null;
        try {

            result = await firstValueFrom(this.engineControllerServiceV2.getEnginesV2(filter.getAllParamsV2()));

        } catch (error) {
            LoggingService.logError(LoggingSource.ENGINE_SERVICE,
                `Backend error occurred when trying to receive engines from  engine-service v2 controller.`, error);
        }

        return result;
    }


    /**
     * This method returns a partNumberdefinition for a given keyCode and partNumber combination.
     * @param keyCode The keyCode we search for.
     * @param partNumber The partNumber we search for.
     * @return Promise<PartNumberDefinitionResponse>
     */

    public async getPartNumberDefinitionByKeyCodeAndPartNumber(keyCode: string, partNumber: string): Promise<PartNumberDefinitionResponse> {
        let result: PartNumberDefinitionResponse = null;

        try {
            const response = await firstValueFrom(
                this.enginePartNumberDefinitionController
                .getLatestPartNumberDefinitionByKeyCodeAndPartNumber({
                    keyCode, partNumber
                }))

            if (response) {
                result = response;
            }

        } catch (error) {
            LoggingService.logError(LoggingSource.ENGINE_SERVICE,
                `Backend error occurred when trying to receive partNumberDefinitions from engine-service controller.`, error);
        }

        return result;
    }

  /**
   * This method returns all partNumberDefinitions for a given partNumber.
   * @param partNumber The partNumber we search for.
   * @return Promise<PartNumberDefinitionResponse>
   */

  public async getPartNumberDefinitionsByPartNumber(partNumber: string): Promise<PartNumberDefinitionResponse[]> {
    let result: PartNumberDefinitionResponse[] = null;

    try {
      const response = await firstValueFrom(
        this.enginePartNumberDefinitionController
          .getAllPartNumberDefinitionsByPartNumber({
             partNumber
          }))

      if (response) {
        result = response;
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying to receive partNumberDefinitions from engine-service controller.`, error);
    }

    return result;
  }

  public async getBlockingActions(partId:string):Promise<Array<BlockingActionResponse>>{
    return firstValueFrom(this.engineControllerService.getBlockingActions(({id: partId})))
  }

  /**
   * This method returns the reworks for a givene engine.
   * @param id The id of the engine to get reworks for.
   * @return Promise<ReworkResponse[]>
   */
  public async getReworks(id: string): Promise<ReworkResponse[]> {
    let result: ReworkResponse[] = null;
    try {

      const response =
        await firstValueFrom(this.engineControllerService.getReworks({ id  }));

         response.sort((a, b) => {
            if (a.deliveredAt > b.deliveredAt) {
              return -1;
            } else {
              return 1;
            }
          });

      result = response;

    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying to receive reworks from engine-service controller.`, error);
    }

    return result;
  }

  public async getDistinctCostCenterAndKeyCodeCombinations(costCenters: Array<string>): Promise<string[][]> {
    try {

      const params: GetDistinctCostCenterKeyCodeCombinationsRequestParams = {
        costCenter: costCenters
      }

      return await
        firstValueFrom(this.billOfMaterialService.getDistinctCostCenterKeyCodeCombinations(params));

    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying to receive distinctCostCenterKeyCodeCombinations from engine-service.`, error);
    }

    return null;
  }

  public async getPartNumberMultiLineUsage(costCenters: Array<string>): Promise<Array<PartNumberMultiUseInfo>> {
    try {

      const params: GetPartNumberMultiLineUsageRequestParams = {
        costCenter: costCenters
      }

      return await
        firstValueFrom(this.billOfMaterialService.getPartNumberMultiLineUsage(params));

    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying to receive partNumberMultiLineUsage from engine-service.`, error);
    }

    return null;
  }

  public async searchReworks(filter: ReworkFilter): Promise<SliceReworkResponse> {
    try {

      console.log(filter.getAllParams());

      const response
        = await firstValueFrom(this.reworkControllerService.searchReworks(filter.getAllParams()))

      return response;

    } catch (error) {

    }

    return null;
  }

  public async countReworks(filter: ReworkFilter): Promise<number> {
    try {

      const response
        = await firstValueFrom(this.reworkControllerService.countReworks(filter.getAllParams()))

      return response;

    } catch (error) {

    }

    return null;
  }

  public async getRework(id: string): Promise<ReworkResponse> {
    try {
      return await firstValueFrom(this.reworkControllerService.getRework({ id }));


    } catch (error) {

    }

    return null;
  }

  public async approveRework(updateRequest: ReworkUpdateRequest2): Promise<ReworkResponse> {
    /**
     * We are mapping the source values, because we currently do not have a patch endpoint to only update the state
     * No values besides closed info and state should change here!
     */

    const request: ReworkUpdateRequest = {
      analysis: updateRequest.reworkResponse.analysis,
      rework: updateRequest.reworkResponse.rework,
      complaint: updateRequest.reworkResponse.complaint,
      chargeTo: updateRequest.reworkResponse.chargeTo,
      minutes: updateRequest.reworkResponse.minutes,
      employee: updateRequest.reworkResponse.employee, // TODO: Should not be needed on update
      source: updateRequest.reworkResponse.source,
      closedAt: updateRequest.reworkResponse.closedAt,
      closedBy: updateRequest.reworkResponse.closedBy,


      /**
       * These are the only values, wie should patch here.
       */
      dispatchedAt: new Date().toISOString(),
      qualityAssuranceCheckedAt: new Date().toISOString(),
      qualityAssuranceEmployee: AuthService.userName,
      reworkState: ReworkState.Approved,
    }



    const params: UpdateReworkRequestParams = {
      id: updateRequest.reworkResponse.id,
      reworkUpdateRequest: request
    }



    const response = await firstValueFrom(this.reworkControllerService.updateRework(params));

    return response;
  }

  public async reopenRework(updateRequest: ReworkUpdateRequest2): Promise<ReworkResponse> {
    const request: ReworkUpdateRequest = {
      /**
       * We are mapping the source values, because we currently do not have a patch endpoint to only update the state
       * No values besides closed info and state should change here!
       */
      analysis: updateRequest.reworkResponse.analysis,
      rework: updateRequest.reworkResponse.rework,
      complaint: updateRequest.reworkResponse.complaint,
      chargeTo: updateRequest.reworkResponse.chargeTo,
      minutes: updateRequest.reworkResponse.minutes,
      employee: updateRequest.reworkResponse.employee,
      source: updateRequest.reworkResponse.source,

      /**
       * These are the only values, wie should patch here.
       */
      closedBy: null,
      closedAt: null,
      reworkState: ReworkState.Open
    }

    const params: UpdateReworkRequestParams = {
      id: updateRequest.reworkResponse.id,
      reworkUpdateRequest: request
    }

    const response = await firstValueFrom(this.reworkControllerService.updateRework(params));

    return response;
  }

  public async closeRework(updateRequest: ReworkUpdateRequest2): Promise<ReworkResponse> {
    const request: ReworkUpdateRequest = {
      /**
       * We are mapping the source values, because we currently do not have a patch endpoint to only update the state
       * No values besides closed info and state should change here!
       */
      analysis: updateRequest.reworkResponse.analysis,
      rework: updateRequest.reworkResponse.rework,
      complaint: updateRequest.reworkResponse.complaint,
      chargeTo: updateRequest.reworkResponse.chargeTo,
      minutes: updateRequest.reworkResponse.minutes,
      employee: updateRequest.reworkResponse.employee,
      source: updateRequest.reworkResponse.source,

      /**
       * These are the only values, wie should patch here.
       */
      closedBy: AuthService.userName,
      closedAt: new Date().toISOString(),
      reworkState: ReworkState.Closed
    }

    const params: UpdateReworkRequestParams = {
      id: updateRequest.reworkResponse.id,
      reworkUpdateRequest: request
    }

    try {
      const response
        = await firstValueFrom(this.reworkControllerService.updateRework(params));

      if (response) {
        return response;
      }
    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying to set rework state to closed engine-service.`, error);
    }

    return null;
  }


  public async updateRework(updateRequest: ReworkUpdateRequest2): Promise<ReworkResponse> {
    const request: ReworkUpdateRequest = {
      analysis: updateRequest.analyses,
      rework: updateRequest.rework,
      complaint: updateRequest.complaint,
      employee: updateRequest.reworkResponse.employee, // TODO: Should not be needed on update
      source: updateRequest.reworkResponse.source,
      reworkState: updateRequest.reworkResponse.reworkState,
      chargeTo: updateRequest.chargeTo,
      minutes: updateRequest.minutes,
      invalidatesColdTests: updateRequest.invalidateColdTest,
      invalidatesHotTests: updateRequest.invalidateHotTest
    }

    const params: UpdateReworkRequestParams = {
      id: updateRequest.reworkResponse.id,
      reworkUpdateRequest: request
    }

    const response = await firstValueFrom(this.reworkControllerService.updateRework(params));

    return response;
  }

  /**
   * Creates a rework for the given engines id
   * @param engineId The id of the engine we want to create a rework for.
   * @param complaint The reason for this rework.
   * @param isInline If the rework is required during production, it is an inline rework, else not.
   * @param costCenter The costCenter of the component we want to create a rework for.
   */
  public async createRework(engineId: string, complaint: string, isInline: boolean = false, costCenter: string = null): Promise<ReworkResponse> {
    try {
      const request: ReworkCreateRequest = {
        complaint,
        analysis: '', // TODO: Should be nullable
        deliveredAt: new Date().toISOString(), // TODO: Should be set at backend
        employee: AuthService.userName,
        source: 'SZONLINEV2',
        reworkType: isInline ? ReworkType.Inline : ReworkType.Offline,
        reworkState: ReworkState.Open, // TODO: Should be set at backend
        costCenter
      }

      const params: CreateReworkRequestParams = {
        id: engineId,
        reworkCreateRequest: request
      }

      return await firstValueFrom(this.engineControllerService.createRework(params));;

    } catch (error) {
      LoggingService.logError(LoggingSource.ENGINE_SERVICE,
        `Backend error occurred when trying to create a new rework for this engine in engine-service.`, error);
    }

    return null;
  }
}

export class ReworkUpdateRequest2 {

  constructor(reworkResponse: ReworkResponse) {
    this.reworkResponse = reworkResponse;
  }

  public complaint: string;
  public analyses = '';
  public rework = '';
  public chargeTo?: string;
  public minutes?: string;

  public invalidateHotTest = false;
  public invalidateColdTest = false;

  public reworkResponse: ReworkResponse;

  public map(rework: string, complaint: string, analyses: string, chargeTo: string, minutes: string) {
    this.rework    = rework;
    this.complaint = complaint;
    this.analyses  = analyses;
    this.chargeTo  = chargeTo;
    this.minutes   = minutes;
  }
}
