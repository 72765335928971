import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EngineService} from '../../services/backend/engine.service';
import {Router} from '@angular/router';
import {Engine} from '../../services/backend/models/engine';

@Component({
  selector: 'op-component-rework-create-wizard',
  templateUrl: './component-rework-create-wizard.component.html',
  styleUrls: ['./component-rework-create-wizard.component.scss']
})
export class ComponentReworkCreateWizardComponent {
  @Input() componentId: string;
  @Input() costCenter: string;

  @Output() onCancel = new EventEmitter();
  @Output() onClose = new EventEmitter();

  public creatingRework = false;
  public creatingReworkFailed = false;
  public goToNewRework = false;

  public intern = false;

  public complaint = '';



  constructor(private engineService: EngineService, private router: Router) {

  }

  public onReworkTypeChanged() {
    this.intern = !this.intern;
  }

  public cancel() {
    this.onCancel.emit();
  }

  public async save() {
    this.creatingReworkFailed = false;

    const response =
      await this.engineService.createRework(this.componentId, this.complaint, this.intern, this.costCenter);

   if (response) {

     if (this.goToNewRework) {
       console.log('/actions/reworks' + response.id);

       this.router.navigateByUrl('/actions/reworks/' + response.id);
     }

     this.onClose.emit();
   } else {
     this.creatingReworkFailed = true;
   }
  }
}
