import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
  AssemblyPartResponse,
  AssemblyResponse, PartAssemblyResponse
} from '@cstx/volkswagen-mqs-part-service-client';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {ErrorHandler} from '../../../services/error-handler/error-handler';
import {PartService} from '../../../services/backend/part.service';
import AssemblyTypeEnum = PartAssemblyResponse.AssemblyTypeEnum;


@Component({
  selector: 'op-assembly-details',
  templateUrl: './assembly-details.component.html',
  styleUrls: ['./assembly-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssemblyDetailsComponent implements OnInit {
  public previousParentComponentsIds: Array<PartAssemblyResponse> = new Array<PartAssemblyResponse>();
  private visiblePreviousAssemblies = 10;

  constructor(private partService: PartService,
              private breakpointObserver: BreakpointObserver,
              private router: Router) { }

  @Input() componentId: string;
  @Input() parentComponentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  @Output() onAssemblyLoaded = new EventEmitter<AssemblyResponse>();

  parentAssembly: AssemblyResponse;
  previousParentComponents: Array<AssemblyResponse> = new Array<AssemblyResponse>();


  loading = false;
  loadingPreviousParentAssemblies = false;

  assembly: AssemblyResponse;

  treeViewConfig = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false
  });

  treeViewItems = Array<TreeviewItem>();

  parentSelfLink: string;
  viewSizeXS: boolean;

  public ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeXS = true;
        } else {
          this.viewSizeXS = false;
        }
      });

    this.loading = true;
    this.parentSelfLink = this.selfLink?.replace(this.contentContainerId, 'parent-' + this.contentContainerId);
    this.getPartParentType();
    this.getAssembly(this.componentId);

  }

  getAssembly(id: string) {
    if (id === undefined) {
      return;
    }
    this.partService.getAssemblyById(id)
      .then(
        response => {
          this.assembly = response;

          if (this.assembly === undefined || this.assembly.assemblyParts.length === 0) {
            return;
          }

          this.assembly.assemblyParts.forEach(assemblyPart => {
              this.treeViewItems.push(new TreeviewItem({text: assemblyPart.part.dmc, value: assemblyPart}));
          });

          this.loadSubAssembly();

        })
      .finally(
        () => {
          this.loading = false;
          this.onAssemblyLoaded.emit(this.assembly);
        });
  }

  getTreeViewItemText(value: any, isGroup?: boolean) {
    let treeViewNodeText: string;
    let item;

    if (!isGroup) {
      item = value as AssemblyPartResponse;

      if (item.part !== undefined && item.part.partNumber !== null) {
        treeViewNodeText = item.part.partNumber;
      } else {
        treeViewNodeText = 'Missing';
      }
    }

    if (isGroup) {
      item = value as AssemblyResponse;

      if (item.id === this.assembly.id) {
        treeViewNodeText = 'Zusammenbau';
      } else {
        treeViewNodeText = (value as AssemblyPartResponse).part.partNumber;
      }
    }
    return treeViewNodeText;
  }

  // TODO: Rework and combine with getTreeViewItemClass if possible


  getTreeViewItemGroupClass(groupValue: any, collapsed?: boolean) {
    const classes = Array<string>();
    classes.push('fas');
    classes.push('pr-1');
    classes.push('fa-fw');

    if (!collapsed) {
      classes.push('fa-folder-open');
    } else {
      classes.push('fa-folder');
    }

    if ((groupValue as AssemblyResponse).assemblyStatus === undefined || (groupValue as AssemblyResponse).assemblyStatus === null) {
      switch ((groupValue as AssemblyPartResponse).assemblyPartStatus) {
        case 'VALID':
          classes.push('itemComplete');
          break;
        case 'INVALID':
          classes.push('itemInvalid');
          break;
        case 'UNKNOWN':
          classes.push('itemUnknown');
          break;
        }
    } else {
      switch ((groupValue as AssemblyResponse).assemblyStatus) {
        case 'COMPLETE':
          classes.push('itemComplete');
          break;
        case 'INCOMPLETE':
          classes.push('itemIncomplete');
          break;
        case 'INVALID':
          classes.push('itemInvalid');
          break;
        case 'UNKNOWN':
          classes.push('itemUnknown');
          break;
      }
    }

    return classes;
  }

  getTreeViewItemClass(assemblyPart: AssemblyPartResponse) {
    const classes = Array<string>();
    classes.push('fa');
    classes.push('fa-cubes');

    switch (assemblyPart.assemblyPartStatus) {
      case 'VALID':
        classes.push('itemComplete');
        break;
      case 'INVALID':
        classes.push('itemInvalid');
        break;
      case 'UNKNOWN':
        classes.push('itemUnknown');
        break;
    }

    return classes;
  }

  getAssemblyPartProductionDate(assemblyPart: AssemblyPartResponse) {
    if (assemblyPart !== undefined && assemblyPart.part !== undefined) {
      return assemblyPart.part.productionDate;
    }
  }

  getTreeViewItemType(value: any) {
    const assemblyPart = value as AssemblyPartResponse;

    if (assemblyPart.position !== undefined && assemblyPart.part !== undefined) {
      return assemblyPart.part.partType;
    }
  }

  getTreeViewItemDMC(value: any) {
    const assemblyPart = value as AssemblyPartResponse;

    if (assemblyPart.position !== undefined && assemblyPart.part !== undefined) {
      return assemblyPart.part.dmc;
    }
  }

  getTreeViewItemPosition(value: any) {
    const assemblyPart = value as AssemblyPartResponse;

    if (assemblyPart.position !== undefined && assemblyPart.part !== undefined) {
      return 'Position: ' + assemblyPart.position;
    }
  }

  private loadSubAssembly(parent?: TreeviewItem) {
    let treeViewItems;
    if (parent === undefined) {
      treeViewItems = this.treeViewItems;
    } else {
      treeViewItems = parent.children;
    }

    if (treeViewItems !== undefined) {
      const parts: Set<string> = new Set<string>();
      treeViewItems.forEach( (child, index) => {
        const assemblyPart = child.value as AssemblyPartResponse;
        if (assemblyPart === undefined || assemblyPart.part === undefined || assemblyPart.part.id === undefined) {
          return;
        }
        parts.add(assemblyPart.part.id);
      });
      this.partService.getAssembliesByIDList(Array.from(parts)).then(assemblyResponses => {
          treeViewItems.forEach(item => {
            const assembly = assemblyResponses.find(_ => _.id === item.value.part?.id);
            if (assembly) {
              const treeviewItemsArray = assembly.assemblyParts.map(p => new TreeviewItem({text: p.part.dmc, value: p}));

              if (treeviewItemsArray.length > 0) {
                item.children = treeviewItemsArray;
              }

            }

            this.loadSubAssembly(item);

          });
      }).catch( error => {
        ErrorHandler.printError(error);
      });
    }
  }

  isPartOfMainAssembly(value: any): boolean {
    if ((value as AssemblyPartResponse).part !== undefined) {
      if (this.assembly.assemblyParts.find(p => p.part.id === (value as AssemblyPartResponse).part.id) === undefined) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  async getPartParentType() {
    if (!this.parentComponentId) {
      return;
    }
    this.parentAssembly = await this.partService.getAssemblyById(this.parentComponentId);
    this.previousParentComponentsIds = (await this.partService.getAllParentAssemblies(this.componentId))
      .filter(assembly => assembly.id !== this.parentComponentId);

    if (this.previousParentComponentsIds.length > 0){
      this.loadingPreviousParentAssemblies = true;
      this.previousParentComponents = await this.loadNextPreviousParentComponents();
      this.loadingPreviousParentAssemblies = false;
    }

  }

  public async loadNextPreviousParentComponents(): Promise<Array<AssemblyResponse>> {
    return this.partService.getAssembliesByIDList(this.previousParentComponentsIds.map(assembly => assembly.id)
      .filter((entry, index) => index >= this.previousParentComponents.length && index < this.previousParentComponents.length + this.visiblePreviousAssemblies));
  }

  getPartIdFromTreeViewItem(treeViewItem: TreeviewItem) {
    const assembly = treeViewItem.value as AssemblyPartResponse;

    if (assembly === undefined) { return ''; }
    if (assembly.part === undefined) { return ''; }

    return assembly.part.id;
  }

  navigateAndReinitialise(path: string) {
    this.router.navigate(['/home/notfound']).then(() =>
      this.router.navigate([ path ]));
  }

  private getCustomParentAssemblyType(assemblyName: string): AssemblyTypeEnum {
    if (assemblyName.length <= 10 && assemblyName.includes('-')) {
      return AssemblyTypeEnum.Engine;
    }

    return AssemblyTypeEnum.Part;
  }

  public loadMorePreviousAssemblies() {
    this.loadingPreviousParentAssemblies = true;
    this.loadNextPreviousParentComponents()
      .then(moreEntries => this.previousParentComponents = this.previousParentComponents.concat(moreEntries))
      .finally(() => this.loadingPreviousParentAssemblies = false);
  }
}
