<op-detail-section-wrapper *ngIf="isActive"
  [title]="'component-profile.actions.title' | translate"
  [description]="'component-profile.actions.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="true"
  [selfLink]="selfLink"
  [collapsable]="false"
  [cancelable]="true"
  (onCancelAction)="isActive = false"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <op-labeling *ngIf="actionsType === ComponentProfileActionType.PRINT_LABEL" [labelingType]="labelingTypes.REWORK" [engine]="engine"></op-labeling>
  <op-labeling *ngIf="actionsType === ComponentProfileActionType.CHANGE_KEYCODE" [labelingType]="labelingTypes.KEYCODECHANGE" [engine]="engine"></op-labeling>

  <div style="width: 90%">
  <op-component-release-wizard
    (onCancel)="isActive = false; ComponentProfileService.componentActionCanceled(ComponentProfileActionType.RELEASE)"
    (onClose)="isActive = false; ComponentProfileService.componentActionExecuted(ComponentProfileActionType.RELEASE)"
    *ngIf="actionsType === ComponentProfileActionType.RELEASE">
  </op-component-release-wizard>
  </div>

  <div style="width: 90%">
    <op-component-block-wizard
      (onCancel)="isActive = false; ComponentProfileService.componentActionCanceled(ComponentProfileActionType.BLOCK)"
      (onClose)="isActive = false; ComponentProfileService.componentActionExecuted(ComponentProfileActionType.BLOCK)"
      *ngIf="actionsType === ComponentProfileActionType.BLOCK">
    </op-component-block-wizard>
  </div>

  <div style="width: 90%">
    <op-component-rework-create-wizard
      [componentId]="engine.id"
      [costCenter]="engine.costCenter"
      (onCancel)="isActive = false; ComponentProfileService.componentActionCanceled(ComponentProfileActionType.CREATE_REWORK)"
      (onClose)="isActive = false; ComponentProfileService.componentActionExecuted(ComponentProfileActionType.CREATE_REWORK)"
      *ngIf="actionsType === ComponentProfileActionType.CREATE_REWORK">
    </op-component-rework-create-wizard>
  </div>
</ng-template>


